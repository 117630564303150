import React, { useState, useEffect, Fragment } from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import MediaData from "../data/2022-06-20_Export_Assets_URLs_Downloadbereich_Website_EN.json"

import { DocumentDownload16 } from "@carbon/icons-react"

const AssetMultiLink = ({ label, value }) => {
  if (value.indexOf("||||") !== -1) {
    return (
      <>
        {value.split("||||").map((singleValue, index) => {
          return (
            <a
              className="inline-block mr-3 whitespace-nowrap text-primary hover:text-primary-hover"
              href={singleValue}
              target="_blank"
              rel="noopener noreferrer"
            >
              <DocumentDownload16 className="ml-auto h-6 inline-block -mt-1 align-middle" />{" "}
              {label} {index + 1}
            </a>
          )
        })}
      </>
    )
  } else {
    return (
      <a
        className="inline-block mr-3 whitespace-nowrap text-primary hover:text-primary-hover"
        href={value}
        target="_blank"
        rel="noopener noreferrer"
      >
        <DocumentDownload16 className="ml-auto h-6 inline-block -mt-1 align-middle" />{" "}
        {label}
      </a>
    )
  }
}

const IndexPage = () => {
  const [searchTerm, setSearchTerm] = useState("")
  const [data, setData] = useState(null)

  useEffect(() => {
    setData(MediaData)
  }, [])

  const removeKey = ["Article number", "Description Variant"]

  return (
    <Layout>
      <div className="-mx-grid mb-4">
        <input
          className="border-gray-100 px-4 py-3 border w-full"
          type="text"
          value={searchTerm}
          placeholder="Search products..."
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <hr className="-mx-8 border-gray-100" />
      <ul className="mt-4 leading-normal">
        {data
          ?.filter((val) => {
            if (searchTerm === "") {
              return val
            } else if (val["Article number"].toString().includes(searchTerm)) {
              return val
            } else if (
              val["Description Variant"]
                ?.toLowerCase()
                .includes(searchTerm.toLowerCase())
            ) {
              return val
            }
            return null
          })
          .map((val, index) => {
            return (
              <Fragment key={index}>
                <li className="flex flex-row flex-wrap -mx-grid pb-4 mb-4 border border-gray-100">
                  <div className="w-full lg:w-1/3 px-grid mt-4">
                    <span className="block font-bold text-primary">
                      {val["Description Variant"]}
                    </span>
                    <span className="text-sm">
                      Art.-No.: {val["Article number"]}
                    </span>
                  </div>
                  <div className="w-full lg:w-2/3 px-grid mt-4">
                    <span className="xl:block mr-2 font-bold">Downloads:</span>

                    {Object.entries(val).map((key, index) => {
                      if (key[1] !== "" && !removeKey.includes(key[0])) {
                        return <AssetMultiLink label={key[0]} value={key[1]} />
                      } else {
                        return null
                      }
                    })}
                  </div>
                </li>
              </Fragment>
            )
          })}
      </ul>
    </Layout>
  )
}

export default IndexPage
